<template>
    <div>
        <div>
            <div>
                <section class="item-types">
                    <h2 class="titlePage_subtitle titlePage">Additions</h2>

                    <div class="row">

                        <div class="col-12 col-md-4" v-if="settings.groups.bug_strip">
                            <form action="" class="form" v-on:submit.prevent>
                                <div>
                                    <p class="text">Bug Strip</p>
                                    <label class="form__label form__label_row"
                                           v-for="elem in settings.groups.bug_strip.choices"
                                           :key="elem.name">
                                        <input type="radio" hidden="hidden"
                                               class="form__radio"
                                               v-bind:checked="elem.default"
                                               name="default"
                                               @change="selectBugStripOption(elem)"
                                        >
                                        <span class="form__radio_icon"></span>
                                        <span class="form__labelTitle">{{elem.name}}</span>
                                    </label>
                                </div>

                            </form>
                            <div class="row align-middle" v-if="bug_strip_colour">
                                <div class="col-6 align-middle">
                                    <p class="text_default">{{bug_strip_colour.name}} <span v-if="bug_strip_colour.is_same_as_frame"> (same as frame)</span> </p>
                                </div>
                                <div class="col-6 align-middle"
                                     @click="openBugColourSelection()"
                                >
                                    <div class="colours colour_box colours_small">
                                    <img class="box__img" :src="bug_strip_colour.image" alt="color.name"
                                         v-if="bug_strip_colour.image">
                                    <p v-else-if="bug_strip_colour.hexcode">
                                        <svg-icon name="palette" class="large_icon box__img"
                                                           :style="{color: bug_strip_colour.hexcode}"/>
                                    </p>
                                    <p v-if="!bug_strip_colour.image && !bug_strip_colour.hexcode">
                                        <svg-icon name="palette" class="large_icon box__img"/>
                                    </p>
                                    </div>
                                </div>
                            </div>
                            <transition name="fade" mode="out-in">
                                <select-colors v-show="selectColours" @onSelect="setColour"></select-colors>
                            </transition>

                        </div>

                        <div class="col-12 col-md-4" v-if="settings.groups.seal">
                            <form action="" class="form" v-on:submit.prevent>
                                <div>
                                    <p class="text">Seal</p>
                                    <label class="form__label form__label_row"
                                           v-for="elem in settings.groups.seal.choices"
                                           :key="elem.name">
                                        <input type="radio" hidden="hidden"
                                               class="form__radio"
                                               name="default"
                                               v-bind:checked="elem.default"
                                               @change="selectSealStripOption(elem)"
                                        >
                                        <span class="form__radio_icon"></span>
                                        <span class="form__labelTitle">{{elem.name}}</span>
                                    </label>
                                </div>

                            </form>

                            <div class="row align-middle" v-if="seal_colour">
                                <div class="col-6 align-middle">
                                    <p class="text_default">{{seal_colour.name}} <span v-if="seal_colour.is_same_as_frame"> (same as frame)</span></p>
                                </div>
                                <div class="col-6 align-middle"
                                     @click="openSealColourSelection()"
                                >
                                    <div class="colours colour_box colours_small">
                                    <img class="box__img" :src="seal_colour.image" alt="seal_colour.name"
                                         v-if="seal_colour.image">
                                    <p v-else-if="seal_colour.hexcode">
                                        <svg-icon name="palette" class="large_icon box__img"
                                                           :style="{color: seal_colour.hexcode}"/>
                                    </p>
                                    <p v-if="!seal_colour.image && !seal_colour.hexcode">
                                        <svg-icon name="palette" class="large_icon box__img"/>
                                    </p>
                                </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-md-4" v-if="settings.groups.interlock">
                            <form action="" class="form" v-on:submit.prevent>
                                <div>
                                    <p class="text">Interlock</p>
                                    <label class="form__label form__label_row"
                                           v-for="elem in settings.groups.interlock.choices"
                                           :key="elem.name">
                                        <input type="checkbox" hidden="hidden"
                                               class="form__radio"
                                               name="interlock"
                                               :value="elem.name"
                                               @change="selectInterlockOption(elem)"
                                               v-model="elem.default"
                                        >
                                        <span class="form__checkbox_icon"></span>
                                        <span class="form__labelTitle">{{elem.name}}</span>
                                    </label>
                                </div>
                            </form>
                            <div class="row align-middle" v-if="interlock_colour">
                                <div class="col-6 align-middle">
                                    <p class="text_default">{{interlock_colour.name}}<span v-if="interlock_colour.is_same_as_frame"> (same as frame)</span></p>
                                </div>
                                <div class="col-6 align-middle"
                                     @click="openInterlockColourSelection()"
                                >
                                    <div class="colours colour_box colours_small">
                                    <img class="box__img" :src="interlock_colour.image" alt="seal_colour.name"
                                         v-if="interlock_colour.image">
                                    <p v-else-if="interlock_colour.hexcode">
                                        <svg-icon name="palette" class="large_icon box__img"
                                                           :style="{color: interlock_colour.hexcode}"/>
                                    </p>
                                    <p v-if="!interlock_colour.image && !interlock_colour.hexcode">
                                        <svg-icon name="palette" class="large_icon box__img"/>
                                    </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-12 col-md-4" v-if="settings.groups.other">
                            <form action="" class="form" v-on:submit.prevent>
                                <div>
                                    <p class="text">Other</p>

                                    <div class="" v-for="(elem, key) in settings.groups.other.choices">
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="form__label form__label_row">
                                                    <input type="checkbox" hidden="hidden"
                                                           class="form__radio"
                                                           name="key"
                                                           v-model="elem.default"
                                                           :value="elem.name"
                                                           @change="selectOtherOption(elem)"
                                                    >
                                                    <span class="form__checkbox_icon"></span>
                                                    <span class="form__labelTitle">{{elem.name}}</span>

                                                </label>

                                            </div>
                                        </div>
                                        <div class="row align-middle" v-if="elem.default && elem.colour">
                                            <div class="col-6 align-middle">
                                                <p class="text_default">{{elem.colour.name}}<span v-if="elem.colour.is_same_as_frame"> (same as frame)</span></p>
                                            </div>
                                            <div class="col-6 align-middle"
                                                 @click="openOtherColourSelection(elem)"
                                            >
                                                <div class="colours colour_box colours_small">
                                                <img class="box__img" :src="elem.colour.image" alt="elem.color.name"
                                                     v-if="elem.colour.image">
                                                <p v-else-if="elem.colour.hexcode">
                                                    <svg-icon name="palette" class="large_icon box__img"
                                                                       :style="{color: elem.colour.hexcode}"/>
                                                </p>
                                                <p v-if="!elem.colour.image && !elem.colour.hexcode">
                                                    <svg-icon name="palette" class="large_icon box__img"/>
                                                </p>
                                                </div>
                                            </div>
                                        </div>


                                    </div>


                                </div>

                            </form>
                        </div>

                    </div>
                  <template v-if="actionLinksPortalTarget">
                    <portal :to="actionLinksPortalTarget">
                      <button type="submit"
                              @click.prevent="save"
                              class="btn btn_transparent"
                      ><svg-icon name="solid/check"/> Done
                      </button>
                    </portal>
                  </template>
                  <template v-else>
                    <button type="submit"
                            @click.prevent="save"
                            class="btn btn_default "
                    ><svg-icon name="solid/check"/> Done
                    </button>
                  </template>
                </section>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapGetters} from 'vuex';
    import {defineAsyncComponent} from "vue";

    const SelectColors = defineAsyncComponent(() => import('../../modals/OptionColours'));

    export default {
        name: "Additions",
        data() {
            return {
                bug_strip_colour: null,
                interlock_colour: null,
                seal_colour: null,
                colourSelection: ''
            }
        },

        components: {
            SelectColors,
        },

        props: ['options', 'package', 'set', 'checkIsSetPackage', 'actionLinksPortalTarget'],

        computed: {
            ...mapState('options', ['name', 'price', 'modalView', 'priceAllOptions', 'type']),

            ...mapGetters({
                order: 'orderPosition/orderPosition',
                selectColours: 'optionColours/openModal',
            }),

            settings() {
                return this.$store.getters['orderPosition/getCurrentOptionSettings'];
            },

        },

        methods: {
            ...mapMutations('options', ['setType']),

            openBugColourSelection() {
                let cg = null;
                _.each(this.settings.groups.bug_strip.choices, i => {
                    if (i.default == true && i.colour_group)
                        cg = i.colour_group;
                })
                if (cg)
                    this.$store.commit('optionColours/colourGroup', cg);
                this.colourSelection = 'bug_strip';
                this.$store.commit('optionColours/openModal', true);
                this.$store.commit('optionColours/getColors');
            },

            openSealColourSelection() {
                let cg = null;
                _.each(this.settings.groups.seal.choices, i => {
                    if (i.default == true && i.colour_group)
                        cg = i.colour_group;
                })
                if (cg)
                    this.$store.commit('optionColours/colourGroup', cg);
                this.colourSelection = 'seal';
                this.$store.commit('optionColours/openModal', true);
                this.$store.commit('optionColours/getColors');
            },

            openInterlockColourSelection() {
                let cg = null;
                _.each(this.settings.groups.interlock.choices, i => {
                    if (i.default == true && i.colour_group)
                        cg = i.colour_group;
                })
                if (cg)
                    this.$store.commit('optionColours/colourGroup', cg);
                this.colourSelection = 'interlock';
                this.$store.commit('optionColours/openModal', true);
                this.$store.commit('optionColours/getColors');
            },

            openOtherColourSelection(elem) {
                if (elem.colour_group)
                    this.$store.commit('optionColours/colourGroup', elem.colour_group);
                this.colourSelection = elem.name;
                this.$store.commit('optionColours/openModal', true);
                this.$store.commit('optionColours/getColors');
            },

            setColour(c) {

                if (this.colourSelection == 'bug_strip') {
                    _.each(this.settings.groups.bug_strip.choices, i => {
                        if (i.default == true) {
                            i.colour = c.colour
                            i.is_same_as_frame = c.is_same_as_frame
                            this.settings.groups.bug_strip.is_same_as_frame = c.is_same_as_frame
                        }
                    });
                    this.bug_strip_colour = c.colour
                } else if (this.colourSelection == 'seal') {
                    _.each(this.settings.groups.seal.choices, i => {
                        if (i.default == true) {
                            i.colour = c.colour
                            i.is_same_as_frame = c.is_same_as_frame
                            this.settings.groups.seal.is_same_as_frame = c.is_same_as_frame
                        }
                    });
                    this.seal_colour = c.colour;
                } else if (this.colourSelection == 'interlock') {
                    _.each(this.settings.groups.interlock.choices, i => {
                        i.colour = c.colour
                        i.is_same_as_frame = c.is_same_as_frame
                        this.settings.groups.interlock.is_same_as_frame = c.is_same_as_frame
                    });
                    this.interlock_colour = c.colour;
                }
                else {
                    _.each(this.settings.groups.other.choices, i => {
                        if (i.name == this.colourSelection){
                            i.colour = c.colour
                            i.is_same_as_frame = c.is_same_as_frame
                        }
                    });
                }

                if (!this.$store.commit('options/updateColour', {option: 'ADD', settings: this.settings})) {
                    return;
                }

                this.colourSelection = '';
            },

            selectBugStripOption(item) {
                _.forEach(this.settings.groups.bug_strip.choices, (i, k) => {
                    i.default = item.name == i.name;
                    if (i.default == true)
                        this.bug_strip_colour = i.colour;
                });

                this.$store.commit('orderPosition/setOption', {option: 'ADD', selection: this.settings});
            },
            selectSealStripOption(item) {
                _.forEach(this.settings.groups.seal.choices, (i, k) => {
                    i.default = item.name == i.name;
                    if (i.default == true)
                        this.seal_colour = i.colour;
                });

                this.$store.commit('orderPosition/setOption', {option: 'ADD', selection: this.settings});
            },
            selectInterlockOption(item) {
                this.interlock_colour = null;
                _.forEach(this.settings.groups.interlock.choices, (i, k) => {
                    if (i.default == true)
                        this.interlock_colour = i.colour;
                });

                this.$store.commit('orderPosition/setOption', {option: 'ADD', selection: this.settings});
            },
            selectOtherOption(item) {

                _.each(this.settings.groups.other, (i, k) => {
                    if (i.name == item.name)
                        i.default = item.selected;
                })

                this.$store.commit('orderPosition/setOption', {option: 'ADD', selection: this.settings});
            },

            save() {
                this.$store.commit('orderPosition/optionNext', 'ADD');
            },

        },

        created() {

            if (this.settings.groups.bug_strip) {
                _.each(this.settings.groups.bug_strip.choices, i => {
                    if (i.default == true && i.colour)
                        this.bug_strip_colour = i.colour;
                });

            }

            if (this.settings.groups.seal)
                _.each(this.settings.groups.seal.choices, i => {
                    if (i.default == true && i.colour)
                        this.seal_colour = i.colour;
                });

            if (this.settings.groups.interlock)
                _.each(this.settings.groups.interlock.choices, i => {
                    if (i.default == true && i.colour)
                        this.interlock_colour = i.colour;
                });

        }
    }
</script>

<style scoped lang="scss">
    @import '../../../../src/assets/scss/utils/vars';

    .form {
        &__input {
            border-color: $navy;
        }

        .bordered {
            border: 1px solid $navy;
            padding: 10px;
            margin-bottom: 20px;
        }

        &__labelTitle {
            color: $navy
        }

        .justify-content-center, .justify-content-between {
            display: flex;
            margin: 10px;
        }

        .btn {
            margin: 20px 0;
            max-width: none;
            width: 100%;
        }
    }

    .form .btn {
        margin-bottom: 0;
    }

    .modal__close {
        color: $green;
    }

    img {
        width: 80px;
        height: auto;
        position: initial;
    }

</style>
